<template>
    <div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <div>
                    <label for="ctaTitle">title</label>
                    <text-input
                      id="ctaTitle"
                      v-model="ctaTitle"
                    />
                    <span v-if="hasError('ctaTitle')" class="form-error">{{ showError('ctaTitle') }}</span>
                </div>
                <div class="mt-8">
                    <label for="ctaSubtitle">subtitle</label>
                    <text-input
                      id="ctaSubtitle"
                      v-model="ctaSubtitle"
                    />
                    <span v-if="hasError('ctaSubtitle')" class="form-error">{{ showError('ctaSubtitle') }}</span>
                </div>
            </div>
            <div class="form-col mt-8 lg:mt-0">
                <div>
                    <label for="buttonText">button text</label>
                    <text-input
                      id="buttonText"
                      v-model="buttonText"
                    />
                </div>
                <div class="mt-8">
                    <label>button link</label>
                    <url-input v-model="buttonLink" :website-id="websiteId"></url-input>
                </div>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import Widget from "@/mixins/Widget";
    import UrlInput from "@/components/cms/UrlInput";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import TextInput from "@/components/ui/TextInput";

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter, UrlInput, TextInput },
        emits: ['save-page-widget'],
        data: () => {
            return {
                ctaTitle: null,
                ctaSubtitle: null,
                buttonText: null,
                buttonLink: null,
                websiteId: null,
                isProcessing: false
            }
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    this.$emit('save-page-widget', {
                        ctaTitle: this.ctaTitle,
                        ctaSubtitle: this.ctaSubtitle,
                        buttonText: this.buttonText,
                        buttonLink: this.buttonLink,
                    });
                }
            },
        },
        validations: {
            'ctaTitle' : 'required',
            'ctaSubtitle' : 'required'
        },
        created() {
            this.initValidator();
            this.websiteId = this.$route.params.websiteId;

            if(this.values.length) {
                this.ctaTitle = this.getValue('ctaTitle');
                this.buttonText = this.getValue('buttonText');
                this.buttonLink = this.getValue('buttonLink');
                this.ctaSubtitle = this.getValue('ctaSubtitle');
            }
        }
    }
</script>
